<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card no-body>
      <div class="card-header">
        <!-- Title & SubTitle -->
        <div>
          <b-card-title>{{ $t(options.title) }}</b-card-title>
          <b-card-sub-title>{{ $t(options.subTitle) }}</b-card-sub-title>
        </div>

        <!-- Card Actions -->
        <div class="heading-elements" v-if="helper">
          <ul class="list-inline mb-0">
            <li>
              <b-button
                variant="flat-secondary"
                class="btn-icon"
                @click="refresh"
              >
                <feather-icon icon="HelpCircleIcon" size="20" />
              </b-button>
            </li>

            <!--
            <li>
              <feather-icon
                icon="HelpCircleIcon"
                :title="options.tooltipTitle"
                v-b-popover.hover.left="options.tooltipContent"
                size="20"
              />
            </li>
            -->
          </ul>
        </div>
      </div>

      <b-sidebar
        bg-variant="dark"
        text-variant="light"
        :visible="sidebarVisible"
        @hidden="sidebarHidden"
        width="450px"
        :title="options.sidebarContent.title"
        right
        backdrop
        shadow
      >
        <sidebar-content :options="options" />
      </b-sidebar>

      <template #header>
        <slot name="header"></slot>
      </template>

      <!-- Card Body  -->
      <b-card-body >
        <template #header>
        <slot name="header"></slot>
      </template>

        <slot></slot>
      </b-card-body>

      <template #footer>
        <slot name="footer"></slot>
      </template>
    </b-card>

    <template #overlay>
      <div class="text-center">
        <b-icon
          icon="hourglass-split"
          font-scale="2"
          animation="cylon"
        ></b-icon>
        <p id="cancel-label">{{ $t('Please wait...') }}</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
var qs = require("qs");

import SidebarContent from "../ag-grid-table/sidebars/SidebarContent.vue";

import
{
  BRow,
  BCol,
  BButton,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
  BSpinner,
  BOverlay
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VBPopover,
    BSpinner,
    BOverlay,
    BButton,

    SidebarContent
  },

  props: {

    options: {
      type: Object,
      default: {
      }
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    helper: {
      type: Boolean,
      default:true
    }

  },

  directives: {
    "b-popover": VBPopover,
  },

  data: function ()
  {
    return {
      sidebarVisible: false
    };
  },
  created() { },
  methods: {

    sidebarHidden()
    {
      this.sidebarVisible = false
    },

    refresh()
    {
      this.$emit('refreshData');

      this.sidebarVisible = !this.sidebarVisible


    }

  },
};
</script>

<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

